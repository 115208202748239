import axios from 'axios';

export default {
  data: () => ({
    txAgentsBalance: 0,
  }),

  mounted() {
    // this.loadTxAgentsBalance();
  },

  methods: {
    async loadTxAgentsBalance() {
      // Submit the form

      try {
        const { data } = await axios.get(`/tx/agents_balance`);

        this.txAgentsBalance = data;

        return true;
      } catch (e) {
        console.log('Error');

        return false;
      }
    },
  },
};
