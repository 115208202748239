import axios from 'axios';

export default {
  data: () => ({
    chapmanAgentsBalance: 0,
  }),

  mounted() {
    this.loadAgentsBalance();
  },

  methods: {
    async loadAgentsBalance() {
      // Submit the form

      try {
        const { data } = await axios.get(`/agents/balance`);

        this.chapmanAgentsBalance = data;

        return true;
      } catch (e) {
        console.log('Error');

        return false;
      }
    },
  },
};
