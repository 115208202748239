<template>
    <div>
        <vue-frappe
            id="test"
            type="bar"
            :isNavigable="true"
            :height="300"
            :labels="labels"
            :colors="['green', 'blue']"
            :dataSets="this.results"
            :tooltipOptions="{
                formatTooltipY: d => 'GMD ' + formatPrice(d),
            }"
        ></vue-frappe>
    </div>
</template>

<script>
import FormatPrice from '../../mixins/FormatPrice';

export default {
    props: ['filterDate', 'results', 'labels'],
    mixins: [FormatPrice],
};
</script>
