import axios from 'axios';

export default {
  data: () => ({
    vendorInfo: null,
  }),

  mounted() {
    this.loadVendorInfo();
  },

  methods: {
    async loadVendorInfo() {
      // Submit the form

      try {
        const { data } = await axios.get(`/vendorInfo`);

        this.vendorInfo = data.data;

        return true;
      } catch (e) {
        console.log('Error');

        return false;
      }
    },
  },
};
